import React from 'react';

import PropTypes from 'prop-types';
import { Avatar } from '../../../Avatar/Avatar';
import classNames from 'classnames';

import './BlogPost.scss';

const BlogPost = ({ medias, hasWEBPSupport }) => {
  const getLifepalMedia = () => (
    <div className="media__wrapper">
      <h2
        className="heading-md-m mt-2 carousel__title--landing text-capitalize text-center"
      >
        Kategori Artikel Pilihan
      </h2>
      <div className="categories">
        <div className="categories__body media">
          {
            medias.map((post) => (
              <div className="article article__media" key={post.id}>
                <div className="article__logo">
                  <i className={classNames("iconSprites", post.iconSprites)} />
                </div>
                <a href={post.link} className="article__body">
                  <span className="article__title heading-xs-r">{post.title}</span>
                </a>
              </div>
            ))
          }
        </div>
      </div>
    </div>
  );

  return (
    <div className="BlogPost">
      {getLifepalMedia()}
    </div>
  );
};

BlogPost.propTypes = {
  medias: PropTypes.arrayOf(
    PropTypes.shape(
      {
        img: PropTypes.string,
        category: PropTypes.string,
        title: PropTypes.string,
        link: PropTypes.string,
      },
    ),
  ),
};

export default BlogPost;
